import React from 'react'
import './styles.css'

const NotFound = () => {
  return (
    <div className='notfound'>
      <h1>Page Not Found</h1>
    </div>
  )
}

export default NotFound